"use client";
import { iSiteInsurance } from "@/app/models/siteInsurance";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import useStore from "@/stores/use-store";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { iSelectItem } from "@/app/models/componentModels/selectItem";

interface Props {
  siteInsurance: iSiteInsurance[];
  translation: any;
  isEdit?: boolean;
}

export default function InsuranceSelect({
  siteInsurance = [],
  translation,
  isEdit,
}: Props) {
  const [insuranceOptions, setInsuranceOptions] = useState<iSelectItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const insuranceList = siteInsurance.filter(
    (insurance) => insurance.category === "Regular");

  const insuranceId = doUseOrderStorageStore(
    useShallow((state) => state.insurance.insuranceId)
  );

  const setAllValues = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setAllInsuranceValues;

  const updateInsuranceValues = useCallback(
    (
      insuranceId: string,
      insuranceLedgerId: string,
      insuranceSize: string,
      insurancePricePerMonth: string,
      insuranceDescription: string
    ) => {
      if (setAllValues) {
        setAllValues({
          insurance: {
            insuranceId,
            insuranceLedgerId,
            insuranceSize,
            insurancePricePerMonth,
            insuranceDescription,
          },
        });
      }
    },
    [setAllValues]
  );

  const setOptions = () => {

    const options = insuranceList.map((insurance) => ({
      value: insurance.id.toString(),
      label: `${insurance.size} ${insurance.itemPrice} ${translation.general.priceUnit}/${translation.general.shortMonth} - ${insurance.description}`,
    }));

    setInsuranceOptions(options);

    if (insuranceId == "" || insuranceId == undefined) {
      const defaultInsurance = insuranceList[0];

      if (defaultInsurance) {
        setSelectedValues([defaultInsurance.id.toString()]);
      }
    } else {
      setSelectedValues([insuranceId]);
    }
  };

  useEffect(() => {
    if (!isEdit) {
      if (insuranceId == "" || insuranceId == undefined) {
        const defaultInsurance = insuranceList[0];
        updateInsuranceValues(
          defaultInsurance.id.toString(),
          defaultInsurance.ledgeritemid,
          defaultInsurance.size,
          defaultInsurance.itemPrice.toString(),
          defaultInsurance.description
        );
      }
    }
    setOptions();
  }, [isEdit, insuranceId, updateInsuranceValues]);

  const getSelectedLabels = () => {
    return insuranceOptions
      .filter((option) => selectedValues.includes(option.value))
      .map((option) => option.label)
      .join(", ");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    let newSelectedValues = [...selectedValues];
    newSelectedValues = [value];

    const selectedInsurance = insuranceList.find(
      (insurance) => insurance.id === Number(value)
    );

    if (selectedInsurance) {
      updateInsuranceValues(
        selectedInsurance.id.toString(),
        selectedInsurance.ledgeritemid,
        selectedInsurance.size,
        selectedInsurance.itemPrice.toString(),
        selectedInsurance.description
      );
    }

    setIsOpen(false);
    setSelectedValues(newSelectedValues);
  };

  return (
    <div>
      <p>
        {translation.pages?.order?.insuranceDescription ??
          "insurance description"}
      </p>

      <div
        className={`select-dropdown mb-6 ${isOpen ? "open" : "closed"}`}
        ref={dropdownRef}
      >
        <input
          type="text"
          readOnly
          value={selectedValues.length ? getSelectedLabels() : ""}
          onClick={toggleDropdown}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-disabled={false}
        />
        <div
          className="arrow-alt"
          onClick={toggleDropdown}
          style={{
            backgroundImage: `url(/assets/img/icons/arrow_${
              isOpen ? "up" : "down"
            }.svg)`,
          }}
        />
        {isOpen && (
          <div className="options-wrapper adjusted-top">
            <ul className="options" role="listbox">
              {insuranceOptions.map((option, index) => (
                <li
                  key={index}
                  className="option"
                  role="option"
                  aria-selected={selectedValues.includes(option.value)}
                  onClick={() => handleOptionClick(option.value)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="bg-gs-grey rounded-lg p-4">
        <p className="font-bold">
          {translation.pages?.order?.whyInsuranceTitle}
        </p>
        <p className="mb-0-i">{translation.pages?.order?.whyInsuranceDescription}</p>
      </div>
    </div>
  );
}
