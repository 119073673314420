"use client"
import React, { useState, useEffect, useRef } from "react";
import { iBlogPage } from "@/app/models/pageTypes/blogPage";
import BlogPostItem from "@/app/components/BlogPostItem/BlogPostItem";
import { eFetchSpecifier } from "@/app/models/enum/fetchSpecifier";
import { iUmbracoGroupResponse } from "@/app/models/apiModels/umbracoGroupResponse";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { iGetContentRequestModel } from "@/app/models/apiModels/requestModels/getContentRequestModel";
import { GetAbsoluteUrlClient, GetFormattedDate, StripHtml } from "@/app/helpers/clientUtilityHelper";
import Loader from "../Loader/Loader";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";
import { logInfo } from "@/app/helpers/azureBlobLogger";

interface Props {
    pageId: string;
    translations: any;
    itemsPerPage: number;
    defaultImage: string;
    language: string;
}

export default function BlogListing({ pageId, translations, itemsPerPage, defaultImage, language }: Props) {
    logInfo("BlogListing.tsx", "BlogListing", `Data: pageId: ${pageId}, itemsPerPage: ${itemsPerPage}, defaultImage: ${defaultImage}, language: ${language}`);
    const router = useRouter();
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const [currentPage, setCurrentPage] = useState(() => parseInt(searchParams.get('page') || '1'));
    const [items, setItems] = useState<iBlogPage[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const isFetching = useRef(false);
    const initialLoad = useRef(true);

    useEffect(() => {
        const page = parseInt(searchParams.get('page') || '1');
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    }, [searchParams]);

    useEffect(() => {
        if (!isFetching.current) {
            if (initialLoad.current) {
                fetchItemsUpToPage(currentPage);
                initialLoad.current = false;
            } else {
                fetchItemsForPage(currentPage);
            }
            const newSearchParams = new URLSearchParams(searchParams.toString());
            newSearchParams.set('page', currentPage.toString());
            router.replace(`${pathname}?${newSearchParams.toString()}`, { scroll: false });
        }
    }, [currentPage]);

    const fetchItemsUpToPage = async (page: number) => {
        await fetchItems(0, page * itemsPerPage);
    };

    const fetchItemsForPage = async (page: number) => {
        await fetchItems((page - 1) * itemsPerPage, itemsPerPage);
    };

    const fetchItems = async (skip: number, take: number) => {
        logInfo("BlogListing.tsx", "fetchItems", `Fetching items: skip: ${skip}, take: ${take}`);
        if (isFetching.current) return;
        isFetching.current = true;
        setLoading(true);

        const requestModel: iGetContentRequestModel = {
            pageId: pageId,
            childrenAncestorOrDescendantsSpecifier: eFetchSpecifier.Descendants,
            previewMode: false,
            skip: skip,
            take: take,
            extraQuery: "sort=createDate%3Adesc",
            language: language,
            contentType: "blogPage"
        };

        try {
            const response = await fetch('/api/content/getContent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestModel),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const umbracoResponsemodel = data.content as iUmbracoGroupResponse;
            logInfo("BlogListing.tsx", "fetchItems", `Fetched items: ${umbracoResponsemodel.items.length} items`);
            const newItems = (umbracoResponsemodel.items ?? []) as iBlogPage[];

            setHasMore(umbracoResponsemodel.total > items.length + newItems.length);
            setItems((prevItems) => [...prevItems, ...newItems]);
        } catch (error) {
            clientLogError("BlogListing.tsx", "fetchItems", error);
        } finally {
            setLoading(false);
            isFetching.current = false;
        }
    };

    const handleShowMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <div className="p-6 xl:p-0">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {items.map((child: iBlogPage, index: number) => (
                    <BlogPostItem
                        key={index}
                        href={child.route.path}
                        title={child.properties.title}
                        image={child.properties.image ? GetAbsoluteUrlClient(child.properties.image[0].url) : defaultImage}
                        text={StripHtml(child.properties.preamble ?? child.properties.mainBody?.markup)}
                        postedDate={GetFormattedDate(child.createDate)}
                    />
                ))}
            </div>
            {loading ? (
                <div className="flex justify-center mt-10">
                    <Loader loading={loading} useFullWindow={false} useNoBackground={true} />
                </div>
            ) : (
                hasMore && (
                    <div className="flex justify-center mt-10">
                        <button className="btn btn-w100p" onClick={handleShowMore}>{translations.general.showMore}</button>
                    </div>
                )
            )}
        </div>
    );
};
