"use client"
import React, { useState, useEffect, useRef } from "react";
import { iFacilityPage } from "@/app/models/pageTypes/facilityPage";
import Loader from "../Loader/Loader";
import { iGetFacilityAndAreaPagesByIdsRequestModel } from "@/app/models/apiModels/requestModels/getFacilityPagesByIdsRequestModel";
import { iAreaPage } from "@/app/models/pageTypes/areaPage";
import FacilityAndAreaCampaignItem from "../FacilityAndAreaCampaignItem/FacilityAndAreaCampaignItem";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";

interface Props {
    pageId: string;
    contentIds: string[];
    translations: any;
    // itemsPerPage: number;
    defaultImage: string;
    country: string;
    language: string;
    previewMode: boolean;
}

export default function CampaignFacilityListing({ pageId, contentIds, translations, defaultImage, country, language, previewMode }: Props) {
    const [items, setItems] = useState<(iFacilityPage | iAreaPage)[]>([]);
    const [loading, setLoading] = useState(false);
    const isFetching = useRef(false);

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        if (isFetching.current) return;
        isFetching.current = true;
        setLoading(true);

        const requestModel: iGetFacilityAndAreaPagesByIdsRequestModel = {
            contentIds: contentIds,
            previewMode: previewMode,
            language: language
        };

        try {
            const response = await fetch('/api/content/getFacilityAndAreaPagesByIds', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestModel),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const facilityPages = data.content as (iFacilityPage | iAreaPage)[];

            setItems(facilityPages);
        } catch (error) {
            clientLogError("CampaignFacilityListing.tsx", "fetchItems", error);
        } finally {
            setLoading(false);
            isFetching.current = false;
        }
    };

    return (
        <div className="p-6 xl:p-0">
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                {items && items.map((page: iFacilityPage | iAreaPage, index: number) => {
                    return (
                        <FacilityAndAreaCampaignItem
                            key={index}
                            country={country}
                            pageData={page}
                            translations={translations}
                            previewMode={previewMode}
                            language={language}
                        />
                    )
                })}
            </div>
            <Loader loading={loading} useFullWindow={true} />
        </div>
    );
};
