'use client';

import StorageSizeCardImage from '../StorageSizeCardImage/StorageSizeCardImage';
import { iStorageSize } from '@/app/models/storageSize';
import styles from './storagesizecard.module.scss';
import { iStorageSizeTracking } from '@/app/models/storageSizeTracking';

interface Props {
    storage: iStorageSize,
    storageTracking?: iStorageSizeTracking,
    translations: any
}

export default function StorageSizeCard({ storage, translations, storageTracking }: Props) {
    const handleLinkClick = (storage: iStorageSize) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "booking_step",
            step: storageTracking ? "size_group_selected_site" : "size_group_selected_start",
            site_code: storageTracking?.facilityId ?? "",
            site_id: storageTracking?.facilityCode ?? "",
            site_name: storageTracking?.facilityName ?? "",
            size: storage.size,
        });
    };

    return (
        <a
            className="no-underline text-center block rounded-3xl border border-gs-grey p-4 hover:border-gs-black-500 focus:outline-none"
            href={storage.link}
            onClick={() => handleLinkClick(storage)}
            key={storage.id}>
            <h3 className="mb-0">{storage.name}</h3>
            <p className="textSmall font-bold mb-2-i">
                {storage.size} m
                <span className="align-super text-[10.5px]">2</span>
            </p>
            <div className={`text-normal mb-6-i ${(storage.specialPrice && storage.specialPrice > 0 && !storage.firstMonthDiscount) ? "line-through" : ""}`}>{` ${translations.general.fromShort} ${storage.price} ${translations.general.priceUnit}/${translations.general.shortMonth} `}</div>

            <div className={`inline-block rounded-lg bg-gray-50 mb-4 lg:mb-6 w-full ${styles.imageContainer}`}>
                <div className="relative w-full pb-[80%] max-w-[200px] mx-auto "> {/* Set height to 80% of the width */}
                    <div className="absolute inset-0 flex items-center justify-center"> {/* Center image */}
                        <StorageSizeCardImage shortName={storage.shortName} />
                        {((storage.specialPrice && storage.specialPrice > 0) || storage.firstMonthDiscount) && (
                            <div className={`${styles.specialPriceLabelContainer}`}>
                                <div className={`description ${styles.specialPriceLabel}`}>
                                    {storage.firstMonthDiscount && (
                                        <>{translations.pages.facilityPage.cheapFirstMonthShort}</>
                                    )}
                                    {!storage.firstMonthDiscount && storage.specialPrice && storage.specialPrice > 0 && (
                                        <>
                                            <span className="font-bold">{translations.pages.facilityPage.rightNow}</span>
                                            {` ${translations.general.fromShort} ${storage.specialPrice} ${translations.general.priceUnit}/${translations.general.shortMonth}`}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>


            <p className="textSmall mb-0-i">{storage.movingBoxesCount} {translations.pages.order.movingBoxes}</p>
            <div className="mt-4 mb-0-i px-4 textSmall">
                <div className="text-center font-semibold">{translations.pages.campaignListingPage.isAdded}</div>
                <div>{translations.pages.campaignListingPage.insuranceText}</div>
            </div>
        </a>);
}


