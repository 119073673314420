import { StateCreator } from "zustand";

export type TrackingState = {
  tracking: {
    siteCode: string;
    siteId: string;
    siteName: string;
    size: string;
    bookingId?: string;
    currency?: string;
    storageValue?: string;
    firstMonthValue?: string;
    ordinaryMonthValue?: string;
    clientType?: string;
    insurance?: string;
    paymentMethod?: string;
    hashedEmail?: string;
    appVersion?: string;
  };
};

export type TrackingActions = {
  setTrackingValue: (key: keyof TrackingState["tracking"], value: any) => void;
  resetTracking: () => void;
};

const initialState: TrackingState = {
  tracking: {
    siteCode: "",
    siteId: "",
    siteName: "",
    size: "",
    bookingId: undefined,
    currency: undefined,
    storageValue: undefined,
    firstMonthValue: undefined,
    ordinaryMonthValue: undefined,
    clientType: undefined,
    insurance: undefined,
    paymentMethod: undefined,
    hashedEmail: undefined,
    appVersion: undefined
  },
};
export const createTrackingSlice: StateCreator<TrackingState & TrackingActions> = (
  set
) => ({
  tracking: {
    siteCode: "",
    siteId: "",
    siteName: "",
    size: "",
    bookingId: undefined,
    currency: undefined,
    storageValue: undefined,
    firstMonthValue: undefined,
    ordinaryMonthValue: undefined,
    clientType: undefined,
    insurance: undefined,
    paymentMethod: undefined,
    hashedEmail: undefined,
    appVersion: undefined
  },
  setTrackingValue: (key, value) =>
    set((state) => ({
      tracking: {
        ...state.tracking,
        [key]: value,
      },
    })),
  resetTracking: () => set(() => initialState),
});
