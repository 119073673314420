'use client';

import { iSelectItem } from '@/app/models/componentModels/selectItem';
import { iDictionary } from '@/app/models/dictionary';
import React, { useState, useEffect, useRef } from 'react';
import { UseFormRegister, FieldErrors, useFormContext } from 'react-hook-form';

interface FormSelectProps {
  name: string;
  label: string;
  register?: UseFormRegister<any>;
  errors?: iDictionary;
  options: iSelectItem[];
  placeholder?: string;
  className?: string;
  isMandatory?: boolean;
  helperText?: string;
  type?: string;
  disabled?: boolean;
  useEmptyDefault?: boolean;
  onError?: (name: string, error: string | string[]) => void;
  multiple?: boolean;
  onChange?: (selected: iSelectItem) => void;
  onValueChange?: (name: string, value: string, countryData?: any) => void;
  onMount?: (name: string, value: string, label: string) => void;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  label,
  register,
  errors,
  options,
  placeholder,
  onChange,
  onError,
  useEmptyDefault = false,
  className = '',
  isMandatory = false,
  helperText = null,
  type = 'text',
  disabled = false,
  multiple = false,
  onValueChange,
  onMount
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsWrapperRef = useRef<HTMLDivElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const useForms = register !== undefined;
  const { setValue, getValues, trigger, formState: { isSubmitted } } = useForms ? useFormContext() : { setValue: () => { }, getValues: () => { }, trigger: () => { }, formState: { isSubmitted: false } };
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (errors && errors[name]) {
      setShowError(true);

      if (typeof errors[name] === 'string') {
        setErrorMessages([errors[name]]);
      } else {
        setErrorMessages(Object.values(errors[name]));
      }

      if (onError) {
        onError(name, errors[name]);
      }

    } else {
      setShowError(false);
    }
  }, [errors]);

  useEffect(() => {
    if (options.some(option => option.isSelected)) {
      setSelectedValues(options.filter(option => option.isSelected).map(option => option.value));
    }
  }, [])

  useEffect(() => {
    const defaultSelected = options.find(option => option.isSelected);
    if (defaultSelected) {
      setSelectedValues([defaultSelected.value]);
    }
  }, [options]);

  useEffect(() => {
    if (register) {
      register(name, { required: isMandatory });
    }
  }, [register, name, isMandatory]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && dropdownRef.current && optionsWrapperRef.current && ulRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const availableSpaceBelow = window.innerHeight - rect.bottom;

      if (availableSpaceBelow < 240) {
        const maxHeight = availableSpaceBelow;
        optionsWrapperRef.current.style.maxHeight = `${maxHeight}px`;
        ulRef.current.style.maxHeight = `${maxHeight - 30}px`;
      } else {
        optionsWrapperRef.current.style.maxHeight = '240px';
        ulRef.current.style.maxHeight = '200px';
      }
    }
  }, [isOpen]);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (value: string) => {
    let newSelectedValues = [...selectedValues];
    if (multiple) {
      if (newSelectedValues.includes(value)) {
        newSelectedValues = newSelectedValues.filter(v => v !== value);
      } else {
        newSelectedValues.push(value);
      }
    } else {
      newSelectedValues = [value];

      setIsOpen(false);
    }
    setSelectedValues(newSelectedValues);
    if (useForms) {
      setValue(name, multiple ? newSelectedValues : newSelectedValues[0]);
    }

    if (isSubmitted && useForms) {
      trigger(name);
    }

    if (onValueChange) {
      const selectedValue = options.find(option => option.value === value);
      onValueChange(name, selectedValue?.value || '', selectedValue?.label || '');
    }

    if (onChange) {
      const selectedValue = options.find(option => option.value === value);

      if (selectedValue) {
        onChange(selectedValue);
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown' && isOpen) {
      event.preventDefault();
      setFocusedIndex((prevIndex) => (prevIndex + 1) % options.length);
    } else if (event.key === 'ArrowUp' && isOpen) {
      event.preventDefault();
      setFocusedIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
    } else if ((event.key === 'Enter' || event.key === ' ') && !isOpen) {
      event.preventDefault();
      setIsOpen(true);
    } else if ((event.key === 'Enter' || event.key === ' ') && isOpen) {
      event.preventDefault();
      if (focusedIndex >= 0 && focusedIndex < options.length) {
        handleOptionClick(options[focusedIndex].value);
      }
    } else if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const getSelectedLabels = () => {
    return options
      .filter(option => selectedValues.includes(option.value))
      .map(option => option.label)
      .join(', ');
  };

  return (
    <div className={`select-dropdown ${className} ${isOpen ? "open" : "closed"} ${showError ? "validation-error" : ""}`} ref={dropdownRef}>
      <label htmlFor={name}>{`${label}${isMandatory ? '*' : ''}`}</label>
      <input
        type="text"
        readOnly
        value={selectedValues.length ? getSelectedLabels() : placeholder}
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-disabled={disabled}
        aria-controls={`${name}-listbox`}
        id={name}
        className={isOpen ? "border-green" : ""}
      />
      <div
        className="arrow"
        onClick={toggleDropdown}
        style={{
          backgroundImage: `url(/assets/img/icons/arrow_${isOpen ? 'up' : 'down'}.svg)`
        }}
      />
      {isOpen && (
        <div className={`options-wrapper ${isOpen ? "border-green" : ""}`} ref={optionsWrapperRef}>
          <ul className="options" role="listbox" id={`${name}-listbox`} ref={ulRef}>
            {options.map((option, index) => (
              <li
                key={index}
                className={`option ${focusedIndex === index ? 'focused' : ''}`}
                role="option"
                aria-selected={selectedValues.includes(option.value)}
                onClick={() => handleOptionClick(option.value)}
                onMouseEnter={() => setFocusedIndex(index)}
              >
                {multiple && (
                  <span aria-hidden="true" className={`checkbox ${selectedValues.includes(option.value) ? "selected" : ""}`}></span>
                )}
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
      {helperText && <span className="helper-text">{helperText}</span>}
      {showError && (
        <span role="alert">{`${errorMessages.join(", ")}`}</span>
      )}
    </div>
  );
};

export default FormSelect;