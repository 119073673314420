'use client';
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";

interface Props {
  pageData: iOrderStoragePage;
  onClose: () => void;
}

export default function NoUnitModal({ pageData, onClose }: Props) {
  const translation = pageData.requestContext.translations;

  return (
    <div className="flex flex-col gap-4">
      <h2>{translation.pages?.order?.noUnitModal.title}</h2>
      <div className="rounded-l bg-gray-200">
        {translation.pages?.order?.noUnitModal.description}
      </div>
      <div className="flex flex-row gap-4 mt-10">
        <button className="btn w-full dark-contrast" onClick={onClose}>
          {translation.pages?.order?.noUnitModal.back}
        </button>
      </div>
    </div>
  );
}
