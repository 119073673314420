import { iRequestContext } from "@/app/models/contextModels/requestContext";
import { iMyPagesStorageData } from "@/app/models/myPages/myPagesStorageData";
import styles from "../mypages.module.scss";
import { formatAreaSize } from "@/app/helpers/numberHelper";
import FormSelect from "../../Forms/FormSelect";
import { iSelectItem } from "@/app/models/componentModels/selectItem";
import { useEffect, useState } from "react";
import { iInsurance } from "@/app/models/apiModels/insurance";
import Loader from "../../Loader/Loader";
import { iMyPagesInsuranceSimpleData } from "@/app/models/myPages/myPagesInsuranceSimpleData";
import { iMyPagesInsuranceRequestModel } from "@/app/models/apiModels/requestModels/myPagesInsuranceRequestModel";
import { logError, logInfo } from "@/app/helpers/azureBlobLogger";
import LinkElement from "../../General/LinkElement";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";
import { iDictionary } from "@/app/models/dictionary";
import { eChevronPosition } from "@/app/models/enum/chevronPosition";

interface Props {
  storageUnit: iMyPagesStorageData;
  requestContext: iRequestContext;
  customerId: string;
  onClose: () => void;
}

export default function ChangeInsurance({
  storageUnit,
  requestContext,
  customerId,
  onClose,
}: Props) {
  const [insuranceOptions, setInsuranceOptions] = useState<iSelectItem[]>([]);
  const [availableDates, setAvailableDates] = useState<iSelectItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorDetected, setErrorDetected] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inputErrors, setInputErrors] = useState<iDictionary>({});
  const [formValues, setFormValues] = useState<iDictionary>();
  const [isValid, setIsValid] = useState<boolean>(false);


  const initError = (title: string = "", message: string = "") => {
    if (title === "") {
      title = requestContext.translations.general.errors.e500.title;
    }

    if (message === "") {
      message = requestContext.translations.general.errors.e500.message;
    }

    setErrorTitle(title);
    setErrorMessage(message);
    setErrorDetected(true);
    setLoading(false);
  };

  const clearError = () => {
    //TODO: Fix this later
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = ""; // Reset width
    window.scrollTo(0, parseInt(scrollY || "0") * -1);

    setErrorDetected(false);
    setErrorTitle("");
    setErrorMessage("");
  };

  useEffect(() => {
    const fetchInsuranceOptions = async () => {
      try {
        logInfo(
          "ChangeInsurance.tsx",
          "fetchInsuranceOptions",
          "Fetching insurance options"
        );
        const response = await fetch("/api/user/getInsurances", {
          method: "POST",
          headers: {
            Country: requestContext.country,
          },
          body: JSON.stringify({ siteId: storageUnit.siteData.id }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const insuranceResponse = (await response.json()) as iInsurance[];
        const insuranceList = insuranceResponse.filter(
          (insurance) => insurance.category === "Regular"
        );

        const options = insuranceList.map((insurance) => ({
          value: insurance.ledgeritemid.toString(),
          label: `${insurance.description} - ${insurance.itemPrice}${requestContext.translations.general.priceUnit}/${requestContext.translations.general.shortMonth}`,
        }));

        setInsuranceOptions(options);
      } catch (error) {
        logError("ChangeInsurance.tsx", "fetchInsuranceOptions", error);
      } finally {
        setLoading(false);
      }
    };

    const getAvailableDates = () => {
      const today = new Date();
      const availableDates = Array.from({ length: 7 }, (_, i) => {
        const date = new Date(today);
        date.setDate(date.getDate() + i);
        return date;
      });

      setAvailableDates(
        availableDates.map((date) => ({
          value: date.toISOString(),
          label: date.toISOString().split("T")[0],
        }))
      );
    };

    getAvailableDates();
    fetchInsuranceOptions();
  }, []);

  const onValueChange = (name: string, value: string, altValue?: string) => {
    let tempFormValues = {...formValues};
    tempFormValues[name] = value;
    tempFormValues[`${name}Description`] = altValue;
    setFormValues(tempFormValues);

  };

  const validateForm = (): boolean => {
    let isValidTemp = true;
    let newInputErrors: iDictionary = {};

    if (!formValues) {
      isValidTemp = false;
      return isValid;
    }

    const insurance = formValues["insurance"] as string;
    if (!insurance || insurance === "") {
      isValidTemp = false;
      newInputErrors = { ...newInputErrors, ["insurance"]: [requestContext.translations.pages?.order.form.fornameError] };
    }

    const startDateString = formValues["startDate"] as string;
    if (!startDateString || startDateString === "") {
      isValidTemp = false;
      newInputErrors = { ...newInputErrors, ["startDate"]: [requestContext.translations.pages?.order.form.surnameError] };
    } else {
      const startDate = new Date(startDateString);
      const today = new Date();

      if (startDate.getFullYear <= today.getFullYear && startDate.getMonth() <= today.getMonth() && startDate.getDate() < today.getDate()) {
        isValidTemp = false;
        newInputErrors = { ...newInputErrors, ["startDate"]: [requestContext.translations.pages?.order.form.dateError] };
      }
    }

    setIsValid(isValidTemp);
    return isValid;
  }

  useEffect(() => {
    validateForm();
  }, [formValues]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (!formValues) {
        throw new Error("No form values");
      }

      if (!validateForm()) {
        setLoading(false);

        return;
      }

      logInfo("ChangeInsurance.tsx", "onSubmit", "Updating insurance");
      const insuranceData: iMyPagesInsuranceSimpleData = {
        description: formValues ? formValues["insurance"] as string : "",
        insuranceSubContractId: storageUnit.unitData.insurance.insuranceSubContractId,
        startDate: formValues ? new Date(formValues["startDate"] as string) : new Date(),
        id: 0
      }

      const insuranceRequestModel: iMyPagesInsuranceRequestModel = {
        contractId: storageUnit.contractId,
        insuraceSubContractID:
          storageUnit.unitData.insurance.insuranceSubContractId,
        newInsurance: insuranceData.description,
        transferDate: new Date(insuranceData.startDate),
        unitId: storageUnit.unitData.unitId,
        unitSubContractId: storageUnit.unitData.unitSubContractId,
        userId: customerId,
        newInsureance: insuranceData,
      };

      const response = await fetch("/api/unit/updateInsurance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: requestContext.country,
        },
        body: JSON.stringify(insuranceRequestModel),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      onClose();
    } catch (error) {
      logError("ChangeInsurance.tsx", "onSubmit", error);
      initError(
        requestContext.translations.pages.changeStorage.errorTitle,
        requestContext.translations.pages.changeStorage.errorMessage
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {errorDetected ? (
        <div>
          <h2>{errorTitle}</h2>
          <div className="mb-8">{errorMessage}</div>
          <LinkElement
            title={requestContext.translations.general.close}
            isButton={true}
            onClick={clearError}
            buttonColorTheme={eButtonColorTheme.DarkContrast}
            className="w-full mt-6"
          >
            {requestContext.translations.general.close}
          </LinkElement>
        </div>
      ) : (
        <>
          <Loader loading={loading} useFullWindow={true} />
          <h2>{requestContext.translations.general.changeInsurance.title}</h2>
          <div className="rounded-l bg-gray-200">
            <div>
              <span>
                {
                  requestContext.translations.general.changeInsurance
                    .storageUnit
                }
                :{" "}
              </span>
              <span className={styles.valueText}>
                {storageUnit.unitData.unitNumber}
              </span>
              <span>
                , {requestContext.translations.general.changeInsurance.size}:{" "}
              </span>
              <span className={styles.valueText}>
                {formatAreaSize(storageUnit.unitData.areaSize)}m²
              </span>
            </div>
            <div>
              <span>
                {
                  requestContext.translations.general.changeInsurance
                    .currentInsurance
                }
                :{" "}
              </span>
              <span className={styles.valueText}>
                {storageUnit.unitData.insurance.oldInsuranceLeaveDate &&
                  new Date(storageUnit.unitData.insurance.oldInsuranceLeaveDate) >
                  new Date()
                  ? `${storageUnit.unitData.insurance.oldInsuranceDescription}, ${storageUnit.unitData.insurance.oldInsurancePrice}${requestContext.translations.general.priceUnit}/${requestContext.translations.general.shortMonth}`
                  : `${storageUnit.unitData.insurance.description}, ${storageUnit.unitData.insurance.price}${requestContext.translations.general.priceUnit}/${requestContext.translations.general.shortMonth}`}
                ,
              </span>
              {storageUnit.unitData.insurance.oldInsuranceLeaveDate &&
                new Date(storageUnit.unitData.insurance.oldInsuranceLeaveDate) >
                new Date() && (
                  <>
                    <br />
                    <span>
                      {
                        requestContext.translations.general.changeInsurance
                          .newInsuranceFrom
                      }
                      :{" "}
                    </span>
                    <span className={styles.valueText}>
                      {storageUnit.unitData.insurance.oldInsuranceLeaveDate},{" "}
                      {storageUnit.unitData.insurance.description},{" "}
                      {storageUnit.unitData.insurance.price}
                      {requestContext.translations.general.priceUnit}/
                      {requestContext.translations.general.shortMonth}
                    </span>
                  </>
                )}
            </div>
          </div>
          <div>
            <FormSelect
              name="insurance"
              label={
                requestContext.translations.general.changeInsurance
                  .chooseNewInsurance
              }
              errors={inputErrors}
              options={insuranceOptions}
              onValueChange={onValueChange}
              placeholder={
                requestContext.translations.general.changeInsurance
                  .chooseInsurance
              }
              useEmptyDefault={true}
              className="mb-6"
            />
            <FormSelect
              name="startDate"
              label={
                requestContext.translations.general.changeInsurance
                  .whatDayDoYouWantToMoveTheInsurance
              }
              errors={inputErrors}
              options={availableDates}
              onValueChange={onValueChange}
              placeholder={requestContext.translations.general.chooseDate}
              useEmptyDefault={true}
            />
          </div>
          <div className="flex flex-row gap-4 mt-10">
            <LinkElement
              title={requestContext.translations.general.close}
              chevronPosition={eChevronPosition.None}
              isButton={true}
              buttonColorTheme={eButtonColorTheme.DarkContrast}
              onClick={onClose}
              className="w-full"
            >
              {requestContext.translations.general.close}
            </LinkElement>
            <LinkElement
              title={requestContext.translations.general.send}
              chevronPosition={eChevronPosition.None}
              isButton={true}
              disabled={!isValid}
              onClick={onSubmit}
              className="w-full"
            >
               {requestContext.translations.general.send}
            </LinkElement>
          </div>
        </>
      )}
    </div>
  );
}
