import { useRouter } from "next/navigation";
import React from "react";
import LinkElement from "../General/LinkElement";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";

interface PaymentButtonProps {
  contentType: string;
  validation: any;
  orderFlowUrls: any;
  country: string;
  groupName:string;
  siteId?: string;
  sizeCodeId?: string;
  translation: any;
  unitId?: string;
  reservationTimeoutTimestamp?: number;
}

export const PaymentButton: React.FC<PaymentButtonProps> = ({
  contentType,
  validation,
  orderFlowUrls,
  country,
  groupName,
  siteId = "",
  sizeCodeId = "",
  translation,
  unitId = "",
  reservationTimeoutTimestamp = 0,
}) => {
  let buttonComponent;
  const router = useRouter();

  switch (contentType) {
    case "orderStorageStep1OrderStorage":
      buttonComponent = (
        <button
          type="button"
          disabled={!validation.termsAccepted || !validation.formValidated}
          className="w-full btn h-14"
          onClick={async () => {
            router.push(
              `${orderFlowUrls.step2Page.url}?siteId=${siteId}&groupName=${groupName}&unitId=${unitId}&timeout=${reservationTimeoutTimestamp}`
            );
          }}
        >
          {translation.pages.order?.paymentContainer.reviewAndOrder ??
            "Review and Order"}
        </button>
      );
      break;
    case "orderStorageStep2ReviewYourInfo":
      buttonComponent = (
        <>
          {/* Desktop */}
          <div className="hidden lg:grid grid-cols-1 xl:grid-cols-2 gap-4 w-full">
            <LinkElement
              title={translation.pages.order?.paymentContainer.back}
              onClick={() =>
                router.push(
                  `${orderFlowUrls.step1Page.url}?siteId=${siteId}&groupName=${groupName}&unitId=${unitId}&edit=true&timeout=${reservationTimeoutTimestamp}`
                )
              }
              buttonColorTheme={eButtonColorTheme.DarkContrast}
              isButton={true}
              className="less-x-padding"
            >
              {translation.pages.order?.paymentContainer.back ?? "Back"}
            </LinkElement>
            <LinkElement
              title={translation.pages.order?.paymentContainer.signAndPay}
              onClick={() =>
                router.push(
                  `${orderFlowUrls.step3Page.url}?siteId=${siteId}&groupName=${groupName}&unitId=${unitId}&timeout=${reservationTimeoutTimestamp}`
                )
              }
              buttonColorTheme={eButtonColorTheme.Green}
              isButton={true}
              className="less-x-padding"
            >
              {translation.pages.order?.paymentContainer.signAndPay}
            </LinkElement>
          </div>
          {/* Mobile */}
          <div className="lg:hidden grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
            <LinkElement
              title={translation.pages.order?.paymentContainer.signAndPay}
              onClick={() =>
                router.push(
                  `${orderFlowUrls.step3Page.url}?siteId=${siteId}&groupName=${groupName}&unitId=${unitId}&timeout=${reservationTimeoutTimestamp}`
                )
              }
              buttonColorTheme={eButtonColorTheme.Green}
              isButton={true}
            >
              {translation.pages.order?.paymentContainer.signAndPay}
            </LinkElement>
            <LinkElement
              title={translation.pages.order?.paymentContainer.back}
              onClick={() =>
                router.push(
                  `${orderFlowUrls.step1Page.url}?siteId=${siteId}&groupName=${groupName}&unitId=${unitId}&edit=true&timeout=${reservationTimeoutTimestamp}`
                )
              }
              buttonColorTheme={eButtonColorTheme.DarkContrast}
              isButton={true}
            >
              {translation.pages.order?.paymentContainer.back ?? "Back"}
            </LinkElement>
          </div>
        </>
      );
      break;
    default:
      buttonComponent = (
        <button
          type="button"
          className="w-full text-gs-white no-underline bg-gs-green text-sm hover:bg-gs-green-300 font-bold p-4 h-14 rounded-3xl"
        >
          {translation.pages.order?.paymentContainer.error ?? "Error"}
        </button>
      );
  }

  return buttonComponent;
};
