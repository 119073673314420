"use client";
import { iGroupSizeObject } from "@/app/models/groupSizeObject";
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import useStore from "@/stores/use-store";
import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import Loader from "../Loader/Loader";
import React from "react";
import { logInfo } from "@/app/helpers/azureBlobLogger";
import styles from "./unitselection.module.scss";
import FormRadiobutton from "../Forms/FormRadiobutton";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";
import { useRouter } from "next/navigation";

interface UnitSelectionProps {
  pageData: iOrderStoragePage;
  translation: any;
  isEdit?: boolean;
  onChange: () => void;
}

export default function UnitSelection({
  pageData,
  translation,
  isEdit,
  onChange
}: UnitSelectionProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const siteId = pageData.searchParams.siteId?.toString();
  const orderFlowUrl =
    pageData.requestContext.configurations.orderingFlow.step1Page;

  const [selectedSizeCodeId, setSelectedSizeCodeId] = useState<string | null>(
    null
  );

  const siteUnit = pageData.properties.unit;
  const groupName = siteUnit?.Size?.GroupName ?? "";
  const movingBoxesCount =
    pageData.requestContext.configurations.sizeConfigurations.movingBoxes[
    siteUnit?.Size?.GroupNameShort ?? "S"
    ];

  const setPaymentValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setPaymentValue;

  const setValidationValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setValidationValue;

  const setAllValues = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setAllUnitValues;

  const unit = doUseOrderStorageStore(useShallow((state) => state.unit));
  const payment = doUseOrderStorageStore(useShallow((state) => state.payment));

  const updateUnitValues = (
    unitId: string,
    unitSizeId: string,
    unitSizeText: string,
    unitSizeTextShort: string,
    unitSizeM2: string
  ) => {
    if (setAllValues) {
      setAllValues({
        unit: {
          unitId,
          unitSizeId,
          unitSizeText,
          unitSizeTextShort,
          unitSizeM2,
        },
      });
    }
  };

  const unReserveUnit = async (unitId: string, sizeId: number) => {
    try {
      const response = await fetch("/api/unit/unReserveUnit", {
        method: "POST",
        headers: {
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify({
          unitId: unitId,
          sizeId: sizeId,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      clientLogError("UnitSelection.tsx", "unReserveUnit", error);
    }
  };


  logInfo("UnitSelection.tsx", "pageData.properties.groupSizes", pageData.properties.groupSizes);
  logInfo("UnitSelection.tsx", "siteUnit", siteUnit);
  const siteSize = pageData.properties.groupSizes;
  const groupSizesArray: iGroupSizeObject[] = [];
  if (siteSize && siteSize.length > 0) {
    siteSize.forEach((size: any) => {
      groupSizesArray.push({
        sizeInM2: size.physicalSize,
        priceInformation: size.priceInformation,
        sizeCodeId: size.sizeCodeId,
        selected: siteUnit?.PhysicalSize == size.physicalSize,
        sizeId: size.sizeId,
        discount: size.splashTypeId,
        firstMonthDiscount: size.imageTypeId === 1 || size.splashTypeId === 1,
        specialPrice: size.ordPrice > 0 ? size.price : size.ordPrice,
      });
    });
  }

  if (
    siteUnit &&
    !groupSizesArray.some(
      (groupSize) => groupSize.sizeInM2 === siteUnit.PhysicalSize
    )
  ) {
    groupSizesArray.push({
      sizeInM2: siteUnit.PhysicalSize,
      priceInformation: siteUnit.MonthRate,
      sizeCodeId: siteUnit.SizeCodeId,
      selected: true,
      sizeId: siteUnit.SizeId,
      discount: siteUnit.Discount,
      firstMonthDiscount: siteUnit.Discount === 1,
      specialPrice: siteUnit.OrdPrice > 0 ? siteUnit.Discount : siteUnit.OrdPrice,
    });
  }

  groupSizesArray.sort((a, b) => a.sizeInM2 - b.sizeInM2);
  const defaultSelected =
    groupSizesArray.find((groupSize) => groupSize.selected) ||
    groupSizesArray[0];

  logInfo("UnitSelection.tsx", "GroupSizeArray", groupSizesArray);

  useEffect(() => {
    if (defaultSelected) {
      setSelectedSizeCodeId(defaultSelected.sizeCodeId);
    }

    if (
      setPaymentValue &&
      (payment.siteId == "" || payment.siteId == undefined)
    ) {
      setPaymentValue("sizeCodeId", siteUnit?.SizeId?.toString() ?? "");
      setPaymentValue("siteId", siteId);
      setPaymentValue("siteCode", pageData.properties.site?.code ?? "");
    }

    if (isEdit && unit.unitId != pageData.properties.unit?.Id) {
      updateUnitValues(
        pageData.properties.unit?.Id ?? "",
        defaultSelected?.sizeCodeId ?? "",
        groupName,
        siteUnit?.Size?.GroupNameShort ?? "",
        defaultSelected?.sizeInM2?.toString() ?? ""
      );
    }

    if (!isEdit) {
      if (unit.unitId == "" || unit.unitId == undefined) {
        updateUnitValues(
          siteUnit?.Id ?? "",
          defaultSelected?.sizeCodeId ?? "",
          groupName,
          siteUnit?.Size?.GroupNameShort ?? "",
          defaultSelected?.sizeInM2?.toString() ?? ""
        );
      }
    }
  }, [isEdit, defaultSelected, updateUnitValues]);

  const handleRadioChange = (sizeCodeId: string) => {
    setLoading(true);

    setSelectedSizeCodeId(sizeCodeId);
    var selectedSize = groupSizesArray.find(
      (groupSize) => groupSize.sizeCodeId === sizeCodeId
    );

    if (setValidationValue) {
      setValidationValue("isAllowedRedirect", true);

      if (siteUnit?.Id && siteUnit?.SizeId) {
        unReserveUnit(siteUnit?.Id, siteUnit?.SizeId);
      }


      if (selectedSize) {
        updateUnitValues(
          siteUnit?.Id ?? "",
          selectedSize.sizeCodeId ?? "",
          groupName,
          siteUnit?.Size?.GroupNameShort ?? "",
          selectedSize.sizeInM2?.toString() ?? ""
        );
      }
      onChange && onChange();

      setLoading(false);
      router.push(
        `${orderFlowUrl.url}?siteId=${siteId}&sizeCodeId=${sizeCodeId}&groupName=${pageData.properties.groupName}&edit=true`
      );


      // window.location.href = `${orderFlowUrl.url}?siteId=${siteId}&sizeCodeId=${sizeCodeId}&groupName=${pageData.properties.groupName}&edit=true`;
    }
  };

  return (
    <>
      <Loader loading={loading} useFullWindow={true} />
      <div className="flex justify-between lg:pt-2	">
        <div className="flex items-center">
          <img
            src={`/assets/img/storageSizes/storage_${siteUnit?.Size?.GroupNameShort || "M"
              }.png`}
            alt="Green Storage"
            width="48"
            height="48"
          />
          <h3 className="font-bold uppercase p-4 mb-0-i">{groupName}</h3>
        </div>
        {/* Desktop View */}
        <div className="hidden lg:flex lg:flex-row items-center">
          {groupSizesArray.length > 0 && (
            <div className={`${styles.unitInformation}`}>
              {groupSizesArray[0].sizeInM2 ===
                groupSizesArray[groupSizesArray.length - 1].sizeInM2 ? (
                <>{`${groupSizesArray[0].sizeInM2} m²`}&nbsp;</>
              ) : (
                <>
                  {`${groupSizesArray[0].sizeInM2} -  ${groupSizesArray[groupSizesArray.length - 1].sizeInM2
                    } m²`}
                  &nbsp;
                </>
              )}
            </div>
          )}
          <div className="px-4 font-bold pb-2 hidden lg:block">.</div>
          <div className={`${styles.unitInformation}`}>
            {movingBoxesCount}{" "}
            <span className="hidden lg:inline">
              {translation.pages.order.movingBoxes}
            </span>
            <span className="lg:hidden">
              {translation.pages.order.movingBoxesShort}
            </span>
          </div>
        </div>
        {/* Mobile View */}
        <div className="flex flex-col lg:hidden items-center">
          <div className="m-auto">
            {groupSizesArray.length > 0 && (
              <div className={`${styles.unitInformation}`}>
                {groupSizesArray[0].sizeInM2 ===
                  groupSizesArray[groupSizesArray.length - 1].sizeInM2 ? (
                  <>{groupSizesArray[0].sizeInM2} m²</>
                ) : (
                  <>
                    {groupSizesArray[0].sizeInM2} -{" "}
                    {groupSizesArray[groupSizesArray.length - 1].sizeInM2} m²
                  </>
                )}
              </div>
            )}
            <div className={`${styles.unitInformation}`}>
              {movingBoxesCount}{" "}
              <span className="hidden lg:inline">
                {translation.pages.order.movingBoxes}
              </span>
              <span className="lg:hidden">
                {translation.pages.order.movingBoxesShort}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-6">
        {groupSizesArray.map((groupSize, index) => (
          <div
            className="flex items-center mt-4 border-b border-gs-grey pb-4"
            key={index}
          >
            <FormRadiobutton
              name={`radio-${groupSize.sizeCodeId}`}
              groupName="size-radio"
              value={groupSize.sizeCodeId}
              checked={selectedSizeCodeId === groupSize.sizeCodeId}
              className={styles.radioButton}
              onChange={() => handleRadioChange(groupSize.sizeCodeId)}
            >
              <>
                <div className="flex text-base justify-between w-full">
                  <span className="text-left font-normal  lg:font-bold min-w-[50px]">{groupSize.sizeInM2} m²</span>
                  {((groupSize.specialPrice && groupSize.specialPrice > 0) || groupSize.firstMonthDiscount) && (
                    <div className={`${styles.specialPriceLabelContainer} flex-1 text-center lg:text-right font-medium  lg:font-bold `}>
                      <div className={`description ${styles.specialPriceLabel}`}>
                        {groupSize.firstMonthDiscount && (
                          <>{translation.pages.facilityPage.cheapFirstMonth}</>
                        )}
                        {!groupSize.firstMonthDiscount && groupSize.specialPrice && groupSize.specialPrice > 0 && (
                          <>
                            <span className="font-bold">{translation.pages.facilityPage.rightNow}</span>
                            {` ${translation.general.fromShort} ${groupSize.specialPrice} ${translation.general.priceUnit}/${translation.general.shortMonth}`}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className={`${styles.priceContainer} text-right font-normal lg:font-bold`}>
                    <span className={`text-right ${groupSize.discount === 1 ? styles.discountedPrice : ""}`}>{`${translation.general.fromShort} ${groupSize.priceInformation} ${translation.general.priceUnit}/${translation.general.shortMonth}*`}</span>
                  </div>
                </div>
              </>
            </FormRadiobutton>
          </div>
        ))}
        <div className="mt-4 mb-0-i text-left textSmall">
          <div><span className="font-semibold">*{translation.pages.campaignListingPage.isAdded}</span> {translation.pages.campaignListingPage.insuranceText}</div>
        </div>
      </div>
    </>
  );
}
