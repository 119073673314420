"use client";
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { iUnitFeature } from "@/app/models/unitFeature";
import { iUnitObject } from "@/app/models/unitObject";
import { useState } from "react";
import FilterUnitSelection from "./FilterUnitSelection";
import React from "react";

interface UnitInformationProps {
  pageData: iOrderStoragePage;
  translation: any;
  isEdit?: boolean;
}

export default function UnitInformation({
  pageData,
  translation,
  isEdit,
}: UnitInformationProps) {
  const unitTranslation = translation.pages?.order?.unitInformation;
  const unit = pageData.properties.unit;
  const unitFeatures = pageData.properties.unitFeatures;

  const renderListItem = (condition: boolean = false, text: string) =>
    condition ? <li>{text}</li> : null;

  return (
    <>
      <div className="inline-flex w-full">
        <ul className="textSmall mb-0-i">
          <li>
            {unitTranslation.unitNumber}: {unit?.UnitNumber}
          </li>
          <li>
            {unitFeatures?.isCold
              ? unitTranslation.outsideUnit
              : unitTranslation.heatedUnit}
          </li>
          {renderListItem(
            unitFeatures?.isGroundFoor,
            unitTranslation.groundFloor
          )}
          {renderListItem(
            !unitFeatures?.isGroundFoor && !unitFeatures?.isDirectAccess,
            unitTranslation.notWheelchairAccessible
          )}
          {renderListItem(
            unitFeatures?.isDirectAccess,
            unitTranslation.directAccess
          )}
          {renderListItem(
            unitFeatures?.isSlantedRoof,
            unitTranslation.slantedRoof
          )}
        </ul>
      </div>
    </>
  );
}
