// export const formatCurrency = (price: number, translations: any, culture: string, country: string): string => {
//     let currency = '';

//     switch (country) {
//       case 'se':
//         currency = 'SEK';
//         break;
//       case 'dk':
//         currency = 'DKK';
//         break;
//       case 'no':
//         currency = 'NOK';
//         break;
//       default:
//         currency = 'SEK'; 
//     }

//     const options: Intl.NumberFormatOptions = {
//       style: 'currency',
//       currency: currency,
//       minimumFractionDigits: Number.isInteger(price) ? 0 : 2,
//       maximumFractionDigits: Number.isInteger(price) ? 0 : 2,
//     };

//     const formattedPrice = new Intl.NumberFormat(culture, options).format(price);

//     return formattedPrice.replace(currency, translations.general.priceUnit);
//   };
export const formatCurrency = (price: number, translations: any): string => {
  // Ensure the price is a number
  if (isNaN(price)) {
    return "0";
  }

  // Format the price with a period as the thousands separator and a comma as the decimal separator
  const formattedPrice = price
    .toFixed(2) // Ensure two decimal places
    .replace('.', ',') // Replace decimal point with comma
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Add thousands separator

  // Append the currency unit from translations
  return `${formattedPrice} ${translations.general.priceUnit}`;
};

  export const formatCurrencyFromString = (priceString?: string, translations?: any, culture?: string, country?: string): string => {
    if (!priceString || !translations || !culture || !country) {
      return "0";
    }

    const normalizedString = priceString.replace(',', '.');
    const price = parseFloat(normalizedString);
  
    if (!isNaN(price)) {
      return formatCurrency(price, translations);
    } else {
      return "0";
    }
  }

  export const formatAreaSize = (areaSize: number): string => {
    if (Number.isInteger(areaSize)) {
      return areaSize.toString();
    } else {
      const formatted = areaSize.toFixed(1);
      return formatted.replace(/(\.0|,0)$/, '');
    }
  };

  export const formatAreaSizeFromString = (areaSizeString?: string): string => {
    if (!areaSizeString) {
      return "0";
    }

    const normalizedString = areaSizeString.replace(',', '.');
    const areaSize = parseFloat(normalizedString);
  
    if (!isNaN(areaSize)) {
      return formatAreaSize(areaSize);
    } else {
      return "0";
    }
  };

  export const  getCurrencyFromCountry =(cultureString: string): string => {
    var cultureCode = "";
    var currency = "DKK";

    if (cultureString.includes("-") || cultureString.includes("_")) {
        let cultureParts = cultureString.split(/[-_]/);
        cultureCode = cultureParts.length > 1 ? cultureParts[1] : cultureParts[0];
    } else if (cultureString.length === 2) {
        cultureCode = cultureString;
    }

    switch (cultureCode.toLowerCase()) {
        case "no":
        case "nb":
        case "nn":
            currency = "NOK";
            break;
        case "da":
        case "dk":
            currency = "DKK";
            break;
        case "se":
        case "sv":
            currency = "SEK";
            break;
    }

    return currency;
}