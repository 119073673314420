import { formatDate } from "@/app/helpers/dateHelper";
import { formatAreaSize, formatCurrency } from "@/app/helpers/numberHelper";
import { iRequestContext } from "@/app/models/contextModels/requestContext";
import { iMyPagesInvoiceData } from "@/app/models/myPages/myPagesInvoiceData";
import LinkElement from "../General/LinkElement";
import { eTarget } from "@/app/models/enum/target";
import { eLinkSize } from "@/app/models/enum/linkSize";
import { eChevronColor } from "@/app/models/enum/chevronColor";
import { eChevronPosition } from "@/app/models/enum/chevronPosition";
import styles from "./mypages.module.scss";
import Accordion from "../Accordion/Accordion";

interface Props {
  invoices: iMyPagesInvoiceData[];
  requestContext: iRequestContext;
  customerId: string;
}

export default function InvoicesList({
  invoices,
  requestContext,
  customerId,
}: Props) {
  return (
    <div className="rounded-3xl bg-white p-6 lg:p-10 mb-10">
      <h2 className="mb-10">
        {requestContext.translations.pages.myPagesPage.invoices}
      </h2>
      <div className=" hidden lg:grid grid-cols-12 gap-4 pl-7 pr-2 py-2">
        <div className="col-span-2">
          <h3>{requestContext.translations.pages.myPagesPage.invoiceDate}</h3>
        </div>
        <div className="col-span-3">
          <h3>{requestContext.translations.pages.myPagesPage.invoice}</h3>
        </div>
        <div className="col-span-1">
          <h3>{requestContext.translations.pages.myPagesPage.link}</h3>
        </div>
        <div className="col-span-2 flex justify-center">
          <h3 className="w-full text-center">
            {requestContext.translations.pages.myPagesPage.status}
          </h3>
        </div>
        <div className="col-span-2">
          <h3>{requestContext.translations.pages.myPagesPage.sum}</h3>
        </div>
        <div className="col-span-2">
          <h3>
            {requestContext.translations.pages.myPagesPage.applicableReceipt}
          </h3>
        </div>
      </div>

      <div className="grid gap-4 lg:hidden">
        {invoices.map((invoice, index) => (
          <Accordion
            key={index}
            title={formatDate(invoice.invoiceDate)}
            titleElement={
              <div className="grid grid-cols-2">
                <div
                  className={`col-span-1 items-center ${styles.mobileHeader}`}
                  data-header={
                    requestContext.translations.pages.myPagesPage.invoiceDate
                  }
                >
                  {formatDate(invoice.invoiceDate)}
                </div>
                <div
                  className={`col-span-1 flex pr-6 items-center ${styles.mobileHeader}`}
                  data-header={
                    requestContext.translations.pages.myPagesPage.status
                  }
                >
                  {invoice.outstanding ? (
                    <LinkElement
                      href={`${requestContext.configurations.pages.invoicePaymentPage.url}?documentId=${invoice.documentId}&customerId=${customerId}`}
                      isStandalone={true}
                      target={eTarget.Blank}
                      linkSize={eLinkSize.Medium}
                      chevronPosition={eChevronPosition.Right}
                      chevronColor={eChevronColor.Green}
                      title={
                        requestContext.translations.pages.myPagesPage.invoice
                      }
                    >
                      {`${
                        requestContext.translations.pages.myPagesPage.pay
                      } ${formatCurrency(
                        invoice.price,
                        requestContext.translations
                      )}`}
                    </LinkElement>
                  ) : (
                    <i
                      title={requestContext.translations.pages.myPagesPage.paid}
                      className="font-color-green fas fa-check-circle"
                    ></i>
                  )}
                </div>
              </div>
            }
            translations={requestContext.translations}
            className={`${styles.mobileInvoiceAccordion} rounded-[16px]`}
          >
            <div key={index} className={`grid grid-cols-2 gap-4`}>
              {/* Faktura */}
              <div
                className={`pb-4 col-span-2 flex items-center flex-wrap ${styles.mobileHeader}`}
                data-header={
                  requestContext.translations.pages.myPagesPage.invoice
                }
              >
                <div className="w-full">
                  {/* First Row */}
                  {`${requestContext.translations.pages.myPagesPage.unit}: ${invoice.storageUnit.unitData.combinedUnitNumberSize}`}
                </div>
                <div className="w-full">
                  {/* Second Row */}
                  {`${invoice.facility.name} (${requestContext.translations.pages.myPagesPage.invoice} #${invoice.invNum})`}
                </div>
              </div>
              {/* Länk */}
              <div
                className={`flex items-center`}
                data-header={requestContext.translations.pages.myPagesPage.link}
              >
                {invoice.documentId && customerId.length > 0 ? (
                  <LinkElement
                    href={`${requestContext.apiV2Url}/users/invoice/${invoice.documentId}/${customerId}`}
                    isStandalone={true}
                    target={eTarget.Blank}
                    linkSize={eLinkSize.Medium}
                    chevronPosition={eChevronPosition.Right}
                    chevronColor={eChevronColor.Black}
                    title={
                      requestContext.translations.pages.myPagesPage.invoice
                    }
                  >
                    {requestContext.translations.pages.myPagesPage.invoice}
                  </LinkElement>
                ) : (
                  <span>N/A</span>
                )}
              </div>
              {/* Applicerbar kvitto */}
              <div
                className={`flex items-center `}
                data-header={
                  requestContext.translations.pages.myPagesPage
                    .applicableReceipt
                }
              >
                {invoice.receipts &&
                  customerId.length > 0 &&
                  invoice.receipts.map((receipt, index) => (
                    <LinkElement
                      key={index}
                      href={`${requestContext.apiV2Url}/users/receipt/${receipt}/${customerId}`}
                      isStandalone={true}
                      target={eTarget.Blank}
                      linkSize={eLinkSize.Medium}
                      chevronPosition={eChevronPosition.Right}
                      chevronColor={eChevronColor.Black}
                      title={
                        requestContext.translations.pages.myPagesPage.invoice
                      }
                    >
                      {requestContext.translations.pages.myPagesPage.receipt}
                    </LinkElement>
                  ))}
              </div>
              {/* Summa */}
              <div
                className={`col-span-2 flex items-center text-right justify-end ${styles.mobileHeader} ${styles.contentRight} ${styles.invoicePrice}`}
                data-header={requestContext.translations.pages.myPagesPage.sum}
              >
                {`${formatCurrency(
                  invoice.price,
                  requestContext.translations
                )}`}
              </div>
            </div>
          </Accordion>
        ))}
      </div>
      <div className="hidden lg:block" id="desktop-invoice-container">
        {invoices.map((invoice, index) => (
          <div
            key={index}
            className={`grid grid-cols-2 lg:grid-cols-12 gap-4 mt-4 lg:pl-7 lg:pr-2 lg:py-2 ${
              index % 2 === 1 ? "rounded-4xl bg-light-gs-green" : ""
            }`}
          >
            {/* Fakturadatum */}
            <div
              className={`col-span-2  flex items-center ${styles.mobileHeader}`}
              data-header={
                requestContext.translations.pages.myPagesPage.invoiceDate
              }
            >
              {formatDate(invoice.invoiceDate)}
            </div>
            {/* Faktura */}
            <div
              className={`pb-0 col-span-3 flex items-center flex-wrap ${styles.mobileHeader}`}
              data-header={
                requestContext.translations.pages.myPagesPage.invoice
              }
            >
              <div className="w-full">
                {/* First Row */}
                {`${requestContext.translations.pages.myPagesPage.unit}: ${invoice.storageUnit.unitData.combinedUnitNumberSize}`}
              </div>
              <div className="w-full">
                {/* Second Row */}
                {`${invoice.facility.name} (${requestContext.translations.pages.myPagesPage.invoice} #${invoice.invNum})`}
              </div>
            </div>
            {/* Länk */}
            <div
              className={`col-span-1 flex items-center`}
              data-header={requestContext.translations.pages.myPagesPage.link}
            >
              {invoice.documentId && customerId.length > 0 ? (
                <LinkElement
                  href={`${requestContext.apiV2Url}/users/invoice/${invoice.documentId}/${customerId}`}
                  isStandalone={true}
                  target={eTarget.Blank}
                  linkSize={eLinkSize.Medium}
                  chevronPosition={eChevronPosition.Right}
                  chevronColor={eChevronColor.Black}
                  title={requestContext.translations.pages.myPagesPage.invoice}
                >
                  {requestContext.translations.pages.myPagesPage.invoice}
                </LinkElement>
              ) : (
                <span>N/A</span>
              )}
            </div>
            {/* Status */}
            <div
              className={`col-span-2 flex justify-center items-center ${styles.mobileHeader} ${styles.contentRight}`}
              data-header={requestContext.translations.pages.myPagesPage.status}
            >
              {invoice.outstanding ? (
                <>
                  <LinkElement
                    href={`${requestContext.configurations.pages.invoicePaymentPage.url}?documentId=${invoice.documentId}&customerId=${customerId}`}
                    isStandalone={true}
                    target={eTarget.Self}
                    linkSize={eLinkSize.Medium}
                    chevronPosition={eChevronPosition.Right}
                    chevronColor={eChevronColor.Green}
                    title={
                      requestContext.translations.pages.myPagesPage.invoice
                    }
                  >
                    {`${
                      requestContext.translations.pages.myPagesPage.pay
                    } ${formatCurrency(
                      invoice.outstandingAmount,
                      requestContext.translations
                    )}`}
                  </LinkElement>
                </>
              ) : (
                <i
                  title={requestContext.translations.pages.myPagesPage.paid}
                  className="font-color-green fas fa-check-circle"
                ></i>
              )}
            </div>
            {/* Summa */}
            <div
              className={`col-span-2 flex items-center text-right justify-center ${styles.mobileHeader} ${styles.contentRight} ${styles.invoicePrice}`}
              data-header={requestContext.translations.pages.myPagesPage.sum}
            >
              {`${formatCurrency(invoice.price, requestContext.translations)}`}
            </div>
            {/* Applicerbar kvitto */}
            <div
              className={`col-span-2 flex items-center `}
              data-header={
                requestContext.translations.pages.myPagesPage.applicableReceipt
              }
            >
              {invoice.receipts &&
                customerId.length > 0 &&
                invoice.receipts.map((receipt, index) => (
                  <LinkElement
                    key={index}
                    href={`${requestContext.apiV2Url}/users/receipt/${receipt}/${customerId}`}
                    isStandalone={true}
                    target={eTarget.Blank}
                    linkSize={eLinkSize.Medium}
                    chevronPosition={eChevronPosition.Right}
                    chevronColor={eChevronColor.Black}
                    title={
                      requestContext.translations.pages.myPagesPage.invoice
                    }
                  >
                    {requestContext.translations.pages.myPagesPage.receipt}
                  </LinkElement>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
