"use client";

import { useState, useEffect, use } from "react";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import useStore from "@/stores/use-store";
import { useShallow } from "zustand/react/shallow";
import FormCheckbox from "../Forms/FormCheckbox";
import LinkElement from "../General/LinkElement";
import { eLinkSize } from "@/app/models/enum/linkSize";
import { eTarget } from "@/app/models/enum/target";
import { iLinkContent } from "@/app/models/iLinkContent";
import { iDictionary } from "@/app/models/dictionary";
import { set } from "lodash";
import styles from "./policiescheckbox.module.scss";

export default function PoliciesCheckbox({
  translation,
  isEdit,
  integrityPolicyLink,
  insuranceTermsLink
}: {
  translation: any;
  isEdit: boolean;
  integrityPolicyLink: iLinkContent;
  insuranceTermsLink: iLinkContent;
}) {
  const setValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setValidationValue;

  const validation = doUseOrderStorageStore(
    useShallow((state) => state.validation.termsAccepted)
  );

  const isCompany = doUseOrderStorageStore(
    useShallow((state) => state.user.isCompany)
  );

  const [integrationPolicyChecked, setIntegrationPolicyChecked] = useState(false);
  const [generalPolicyChecked, setGeneralPolicyChecked] = useState(false);
  const [approvalPolicyChecked, setApprovalPolicyChecked] = useState(false);
  const [companyPolicyChecked, setCompanyPolicyChecked] = useState(false);
  const [integrationPolicyDirty, setIntegrationPolicyDirty] = useState(false);
  const [generalPolicyDirty, setGeneralPolicyDirty] = useState(false);
  const [approvalPolicyDirty, setApprovalPolicyDirty] = useState(false);
  const [companyPolicyDirty, setCompanyPolicyDirty] = useState(false);
  const [errorList, setErrorList] = useState<iDictionary>();

  useEffect(() => {
    if (isEdit && validation) {
      setIntegrationPolicyChecked(true);
      setGeneralPolicyChecked(true);
      setApprovalPolicyChecked(true);
      setCompanyPolicyChecked(true);
    }
  }, [isEdit, validation]);

  const addError = (tempErrorList: iDictionary, key: string, message: string) => {
    if (!tempErrorList[key]) {
      tempErrorList[key] = [message];
    } else {
      const newSpecifiedErrorList = tempErrorList[key] as string[];
      tempErrorList[key] = [...newSpecifiedErrorList, message];
    }
    return tempErrorList;
  };

  useEffect(() => {
    let tempErrorList = {};

    if (!integrationPolicyChecked) {
      if (integrationPolicyDirty) {
        tempErrorList = addError(tempErrorList, "integration-policy-checkbox", "");
      }
    }

    if (!generalPolicyChecked) {
      if (generalPolicyDirty) {
        tempErrorList = addError(tempErrorList, "general-policy-checkbox", "");
      }
    }

    if (!approvalPolicyChecked) {
      if (approvalPolicyDirty) {
        tempErrorList = addError(tempErrorList, "approval-policy-checkbox", "");
      }
    }
   
    if (isCompany) {
      if (!companyPolicyChecked) {
        if (companyPolicyDirty) {
          tempErrorList = addError(tempErrorList, "company-policy-checkbox", "");
        }
      }
    }

    setErrorList(tempErrorList);
  }, [integrationPolicyChecked, generalPolicyChecked, approvalPolicyChecked, companyPolicyChecked]);

  useEffect(() => {
    if (isCompany === true) {
      if (
        integrationPolicyChecked &&
        generalPolicyChecked &&
        approvalPolicyChecked &&
        companyPolicyChecked 
      ) {
        setValue?.("termsAccepted", true);
      } else {
        setValue?.("termsAccepted", false);
      }
    }
    else {
      setCompanyPolicyChecked(false);
      if (
        integrationPolicyChecked &&
        generalPolicyChecked &&
        approvalPolicyChecked
      ) {
        setValue?.("termsAccepted", true);
      } else {
        setValue?.("termsAccepted", false);
      }
    }
  }, [
    isCompany,
    integrationPolicyChecked,
    generalPolicyChecked,
    approvalPolicyChecked,
    companyPolicyChecked,
    setValue,
  ]);

  return (
    <>
      <div className={`grid grid-cols-1 ${styles.labelOverride}`}>
        <FormCheckbox
          name="integration-policy-checkbox"
          checked={integrationPolicyChecked}
          onChange={(e) => {
            setIntegrationPolicyChecked(e.target.checked);
            setIntegrationPolicyDirty(true);
          }}
          errors={errorList}
        >
          {translation.pages?.order?.integrityPolicy}
          <LinkElement
            href={integrityPolicyLink.url}
            title={translation.pages?.order?.integrityPolicyLinkText}
            className="ml-1"
            linkSize={eLinkSize.Large}
            target={eTarget.Blank}
          >
            {translation.pages?.order?.integrityPolicyLinkText}
          </LinkElement>.
        </FormCheckbox>
        <FormCheckbox
          name="general-policy-checkbox"
          checked={generalPolicyChecked}
          onChange={(e) => {
            setGeneralPolicyChecked(e.target.checked);
            setGeneralPolicyDirty(true);
          }}
          errors={errorList}
        >
          {translation.pages?.order?.approvalInsurance}
          <LinkElement
            href={insuranceTermsLink.url}
            title={translation.pages?.order?.approvalInsuranceLinkText}
            className="ml-1"
            linkSize={eLinkSize.Large}
            target={eTarget.Blank}
          >
            {translation.pages?.order?.approvalInsuranceLinkText}
          </LinkElement>.
        </FormCheckbox>
        <FormCheckbox
          name="approval-policy-checkbox"
          checked={approvalPolicyChecked}
          label={translation.pages?.order?.approvalRightOfWithdrawal}
          onChange={(e) => {
            setApprovalPolicyChecked(e.target.checked);
            setApprovalPolicyDirty(true);
          }}
          errors={errorList}
        />
        {isCompany && (
          <FormCheckbox
            name="company-policy-checkbox"
            checked={companyPolicyChecked}
            label={translation.pages?.order?.approvalRightToSignForCompany}
            onChange={(e) => {
              setCompanyPolicyChecked(e.target.checked);
              setCompanyPolicyDirty(true);
            }}
            errors={errorList}
          />
        )}
      </div>
    </>
  );
}