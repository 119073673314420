"use client";
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { useEffect, useState } from "react";

interface Props {
  pageData: iOrderStoragePage;
  showSimple?: boolean;
}

export default function CountdownTimer({
  pageData,
  showSimple = false,
}: Props) {
  const seconds = pageData.properties.countDownSetting;
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft === 0) {
      window.location.href = `${pageData.requestContext.mainUrl}/${pageData.requestContext.culture}`;
      // window.location.href = pageData.requestContext.;
    }
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const roundedSeconds = Math.floor(seconds); // Ensure seconds is an integer
    const m = Math.floor(roundedSeconds / 60);
    const s = roundedSeconds % 60;
    return `${m}:${s < 10 ? "0" : ""}${s}`;
  };

  return (
    <div className="textSmall mb-0-i !text-gs-white text-left">
      {!showSimple && (
        <span>
          {`${pageData.requestContext.translations.pages.order.reservedStorage}`}
        </span>
      )}
        <span>
          {
            pageData.requestContext.translations.pages.order
              .reservedStorageTimeLeft
          }
        </span>
        <span className="font-bold"> {formatTime(timeLeft)}</span>
    </div>
  );
}
