"use client";
import { iAreaSimpleFlatModel } from '@/app/models/apiModels/areaSimpelFlat';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import { iMapMarker } from '@/app/models/mapMarker';
import Accordion from '../Accordion/Accordion';
import { useEffect, useState } from 'react';
import Loader from '../Loader/Loader';
import { iFacilitySimpelExtendedModel } from '@/app/models/apiModels/facilitySimpelExtended';
import { iGetExtendedFacilityDataRequestModel } from '@/app/models/apiModels/requestModels/getExtendedFacilityDataRequestModel';
import { iFacilityExtendedData } from '@/app/models/apiModels/facilityExtendedData';
import FacilitySizeItem from '../FacilitySizeItem/FacilitySizeItem';
import styles from './sizebyarea.module.scss';
import { clientLogError, clientLogInfo } from '@/app/helpers/clientLoggingHelper';
import FacilityMapMarkerComponent from '../GoogleMaps/FacilityMapMarkerComponent';

interface Props {
    area: iAreaSimpleFlatModel;
    sizeCodeId: string;
    groupName: string;
    translations: any;
    country: string;
    countdownTimer: number;
    orderUrl: string;
    expandOnLoad?: boolean;
    sizeInM2?: string;
}


export default function FacilitySizesInArea({ area, sizeCodeId, groupName, translations, country, countdownTimer, orderUrl, expandOnLoad, sizeInM2 }: Props) {
    const [isPricesFetched, setIsPricesFetched] = useState<boolean>(false);
    const [isFacilitiesLoading, setIsFacilitiesLoading] = useState<boolean>(false);
    const [isMarkersLoading, setIsMarkersLoading] = useState<boolean>(true);
    const [isAreaLoading, setIsAreaLoading] = useState<boolean>(true);
    const [facilities, setFacilities] = useState<iFacilitySimpelExtendedModel[]>();
    const [currentArea, setCurrentArea] = useState<iAreaSimpleFlatModel>();
    const [markers, setMarkers] = useState<iMapMarker[]>([]);

    useEffect(() => {
        setCurrentArea(area);
    }, [area]);

    useEffect(() => {
        setFacilities(area.facilities);
        setIsAreaLoading(false);
    }, [currentArea]);

    useEffect(() => {
        setIsFacilitiesLoading(false);
        if (!facilities) {
            return;
        }
        const tempMarkers = facilities.map(facility => ({
            title: facility.name,
            latitude: facility.latitude,
            longitude: facility.longitude,
            markup: (<FacilityMapMarkerComponent
                title={facility.name}
                streetAddress={facility.streetAddress}
                city={facility.city}
                linkText={`${translations.general.goTo} ${facility.name}`}
                linkUrl={facility.path}
            />)
        }));
        setMarkers(tempMarkers);
    }, [facilities]);

    useEffect(() => {
        setIsMarkersLoading(false);
    }, [markers]);

    const toggleExpand = (isExpanding: boolean) => {
        if (isExpanding && !isPricesFetched) {
            setIsFacilitiesLoading(true);

            const fetchSize = async () => {
                try {
                    if (!facilities) {
                        return;
                    }

                    const updatedFacilities = await Promise.all(facilities.map(async (facility) => {
                        try {
                            const extendedFacilityDataRequestModel: iGetExtendedFacilityDataRequestModel = {
                                siteId: facility.id,
                                sizeCodeId: sizeCodeId,
                                groupName: groupName,
                                countdownTimer: countdownTimer,
                            };

                            clientLogInfo('FacilitySizesInArea.tsx', 'fetchSize', `Request: ${JSON.stringify(extendedFacilityDataRequestModel)}`);

                            const extendedFacilityDataResponse = await fetch('/api/site/getExtendedFacilityData', {
                                method: 'POST',
                                headers: {
                                    'country': country,
                                },
                                body: JSON.stringify(extendedFacilityDataRequestModel),
                            });

                            if (!extendedFacilityDataResponse.ok) {
                                clientLogError('FacilitySizesInArea.tsx', 'fetchSize', `Network response was not ok: ${JSON.stringify(extendedFacilityDataResponse)}`);
                                throw new Error('Network response was not ok');
                            }


                            const facilityExtendedData = await extendedFacilityDataResponse.json() as iFacilityExtendedData;
                            clientLogInfo('FacilitySizesInArea.tsx', 'fetchSize', `Response ok: ${JSON.stringify(facilityExtendedData)}`);

                            facility.isAvailable = facilityExtendedData.isAvailable;
                            facility.lowestPriceForSize = facilityExtendedData.lowestPriceForSize;
                            facility.prices = facilityExtendedData.prices;
                            facility.code = facilityExtendedData.code;
                            facility.sizeInM2 = facilityExtendedData.sizeInM2;

                            return facility;
                        } catch (error) {
                            clientLogError('FacilitySizesInArea.tsx', 'fetchSize', error);
                            return facility;
                        }
                    }));

                    setFacilities(updatedFacilities);
                    setIsPricesFetched(true);
                } catch (error) {
                    clientLogError('FacilitySizesInArea.tsx', 'fetchSize', error);
                } finally {
                    setIsFacilitiesLoading(false);
                }
            };


            if (facilities) {
                fetchSize();
            }
        }
    }

    return (
        <div className={`area mb-20`}>
            <Loader loading={isAreaLoading} useFullWindow={false} useNoBackground={true} />

            {area && (
                <>
                    <h2>{area.name}</h2>
                    <div className="mb-10">{area.description}</div>
                    <Loader loading={isMarkersLoading} useFullWindow={false} useNoBackground={true} />
                    {markers && markers.length > 0 && (
                        <GoogleMaps markers={markers}
                            mapId={`map-${area.id}`}
                            className="min-h-[400px] rounded-3xl mb-10"
                            autoAdjustZoom={true} />
                    )}
                    <Accordion
                        title={translations.pages.sizeByAreaPage.showFacilities}
                        translations={translations}
                        onExpandCallback={toggleExpand}
                        className={`rounded-[16px] ${styles.facilityAccordion}`}
                        isExpandedOnLoad={expandOnLoad}>
                        <>
                            <Loader loading={isFacilitiesLoading} useFullWindow={false} useNoBackground={true} />
                            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                                {!isFacilitiesLoading && facilities && facilities.map((facility, index) => {
                                    return (
                                        <div key={index} >
                                            <FacilitySizeItem facility={facility} translations={translations} orderUrl={orderUrl} groupName={groupName} sizeInM2={sizeInM2} />
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    </Accordion>
                </>
            )}
        </div>
    );
}



