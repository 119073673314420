'use client';
import React from "react";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import useStore from "@/stores/use-store";
import { useShallow } from "zustand/react/shallow";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";

export interface Props {
    translations: any;
    androidAppLink?: string;
    iOSAppLink?: string;
    language: string;
}

export default function AppLinks({ translations, androidAppLink, iOSAppLink, language }: Props) {
    const trackingStore = doUseOrderStorageStore(useShallow((state) => state.tracking));

    const handleDownloadAppClick = (app: string) => {
        if (!trackingStore
            || !app
        ) {
            return;
        }

        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "booking_step",
                step: "download_app",
                site_code: trackingStore?.siteCode ?? "",
                site_id: trackingStore?.siteId ?? "",
                site_name: trackingStore?.siteName ?? "",
                size: trackingStore?.size ?? "",
                booking_id: trackingStore?.bookingId ?? "",
                currency: trackingStore?.currency ?? "",
                storage_value: trackingStore?.storageValue ?? "",
                first_month_value: trackingStore?.firstMonthValue ?? "",
                ordinary_month_value: trackingStore?.ordinaryMonthValue ?? "",
                client_type: trackingStore?.clientType ?? "",
                insurance: trackingStore?.insurance ?? "",
                payment_method: trackingStore?.paymentMethod ?? "",
                hashed_email: trackingStore?.hashedEmail ?? "",
                app_version: app
            });
        } catch (error) {
            clientLogError("AppLinks", "Tracing", "Could not trace clicking App Links", error);
        }
    }

    return (
        <div className="flex justify-center mt-6 gap-4">
            <a href={androidAppLink} target="_blank" rel="noreferrer" onClick={() => handleDownloadAppClick("android")}>
                <img src={`/assets/img/appDownload/googlePlay_${language}.png`} alt={translations.general.downloadGooglePlay} className="h-[32px] lg:h-[28px]" />
            </a>
            <a href={iOSAppLink} target="_blank" rel="noreferrer" onClick={() => handleDownloadAppClick("iphone")}>
                <img src={`/assets/img/appDownload/appStore_${language}.svg`} alt={translations.general.downloadAppStore} className="h-[32px] lg:h-[28px]" />
            </a>
        </div>

    );
}