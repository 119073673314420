"use client";

import React from "react";
import styles from "./facilitysizeitem.module.scss";
import { iFacilitySimpelExtendedModel } from "@/app/models/apiModels/facilitySimpelExtended";

export interface Props {
    facility: iFacilitySimpelExtendedModel;
    translations: any;
    className?: string;
    orderUrl: string;
    groupName: string;
    sizeInM2?: string;
}

export default function FacilitySizeItem({ facility, translations, orderUrl, className = "", groupName, sizeInM2 }: Props) {
    const handleLinkClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "booking_step",
            step: "site_selected",
            site_code: facility.id,
            site_id: facility.code,
            site_name: `${facility.city.trim().replace(/\s/g, "_")}-${facility.name.trim().replace(/\s/g, "_")}`,
            size: facility.sizeInM2,
        });
    };

    const facilityMarkup = (
        <div className="relative  flex flex-col h-full">
            <div className={`p-10 pb-4 flex flex-col flex-grow ${styles.titleContainer}`}>
                {facility.city && (
                    <div className="h4 mb-0-i">{facility.city}</div>
                )}
                <h3 className="mb-1-i">
                    {facility.name}
                </h3>
                <div className="text-normal mb-0-i">
                    {facility.streetAddress}
                </div>
            </div>
            {(facility.prices?.discountPrice || facility.prices?.firstMonthDiscount) ? (
                <>
                    <div className="text-normal mb-0-i discount-area flex flex-col h-[112px]">

                        {facility.prices.firstMonthDiscount ? (
                            <div>
                                <div className="text-l">{translations.pages.facilityPage.rightNow}</div>
                                <div className="text-4xl">{translations.pages.campaignListingPage.firstMonthDiscountPrice}</div>
                                <div className="text-l">{translations.pages.campaignListingPage.firstMonthDiscountText}</div>
                            </div>
                        ) : (
                            <div>
                                <div className="text-l">{translations.pages.facilityPage.rightNow}</div>
                                <div className="text-4xl">{`${translations.general.fromShort} ${facility.prices.discountPrice} `}</div>
                                <div className="text-l">{`${translations.general.priceUnit}/${translations.general.shortMonth}`}</div>
                            </div>
                        )}
                    </div>
                    <div className={`${!facility.prices.firstMonthDiscount ? styles.ordinaryPrice : ""} text-center text-2xl`}>
                        {`${translations.general.fromShort} ${facility.prices.ordPrice} ${translations.general.priceUnit}/${translations.general.shortMonth}`}
                    </div>
                </>
            ) : (
                <div className="text-normal no-discount-area flex flex-col h-[112px]">
                    <div className="text-3xl">
                        {`${translations.general.fromShort} ${facility.prices?.ordPrice} ${translations.general.priceUnit}/${translations.general.shortMonth}`}
                    </div>
                </div>
            )}

            <div className="p-10 pt-4 flex flex-col">
                <h3 className={`${styles.action} ${facility.prices?.discountPrice || facility.prices?.firstMonthDiscount ? styles.actionDiscount : ""} ${!facility.isAvailable ? styles.actionDisabled : ""} mb-0-i text-center`}>
                    {facility.isAvailable ? translations.pages.sizeByAreaPage.bookNow : translations.pages.sizeByAreaPage.noVacancies}
                </h3>
                <div className="mt-4 mb-0-i textSmall text-center">
                    <div className="text-center font-semibold">{translations.pages.campaignListingPage.isAdded}</div>
                    <div>{translations.pages.campaignListingPage.insuranceText}</div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={`rounded-3xl card-item flex flex-col h-full`}>
            {facility.isAvailable ? (
                <a
                    href={`${orderUrl}?siteId=${facility.id}&groupName=${groupName}`}
                    className={`relative rounded-3xl bg-light-gs-green ${className}  flex flex-col h-full`}
                    onClick={handleLinkClick}
                >
                    {facilityMarkup}
                </a>
            ) : (
                <div className={`relative rounded-3xl bg-light-gs-green ${className} disabled-item flex flex-col h-full`}>
                    {facilityMarkup}
                </div>
            )}

        </div >
    );
}
