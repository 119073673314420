"use client";
import { formatAreaSize, formatCurrency } from "@/app/helpers/numberHelper";
import { iRequestContext } from "@/app/models/contextModels/requestContext";
import { iMyPagesStorageData } from "@/app/models/myPages/myPagesStorageData";
import Modal from "../Modal/Modal";
import { useState } from "react";
import ChangeInsurance from "./Modals/ChangeInsurance";
import TerminateStorageUnit from "./Modals/TerminateStorageUnit";
import Accordion from "../Accordion/Accordion";
import styles from "./mypages.module.scss";
import Loader from "../Loader/Loader";

interface Props {
  storageUnits: iMyPagesStorageData[];
  requestContext: iRequestContext;
  customerId: string;
  canChangeUnit: boolean;
}

export default function StorageUnitsList({
  storageUnits,
  requestContext,
  customerId,
  canChangeUnit,
}: Props) {
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);
  const [isTerminateModalOpen, setIsTerminateModalOpen] = useState(false);
  const [selectedStorageUnit, setSelectedStorageUnit] =
    useState<iMyPagesStorageData | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenInsuranceModal = (storageUnit: iMyPagesStorageData) => {
    setSelectedStorageUnit(storageUnit);
    setIsInsuranceModalOpen(true);
  };

  const handleCloseInsuranceModal = () => {
    setIsInsuranceModalOpen(false);
    setIsLoading(true);
    location.reload();
  };

  const handleOpenTerminateModal = (storageUnit: iMyPagesStorageData) => {
    setSelectedStorageUnit(storageUnit);
    setIsTerminateModalOpen(true);
  };

  const handleCloseTerminateModal = () => {
    setIsTerminateModalOpen(false);
    setIsLoading(true);
    location.reload();
  };

  const showMainLoader = () => {
    setIsLoading(true);
  };

  return (
    <div className="rounded-3xl bg-white p-6 lg:p-10 mb-10">
      <h2 className="mb-10">
        {requestContext.translations.pages.myPagesPage.myStorageUnits}
      </h2>
      <div className="hidden lg:grid grid-cols-12 gap-4 pl-7 pr-2 py-2">
        <div className="col-span-3">
          <h3>{requestContext.translations.pages.myPagesPage.unit}</h3>
        </div>
        <div className="col-span-1">
          <h3>{requestContext.translations.pages.myPagesPage.number}</h3>
        </div>
        <div className="col-span-1">
          <h3>{requestContext.translations.general.price}</h3>
        </div>
        <div className="col-span-2">
          <h3>{requestContext.translations.pages.myPagesPage.status}</h3>
        </div>
        <div className="col-span-5"></div>
      </div>
      <Loader
        loading={isLoading}
        useFullWindow={true}
        useNoBackground={false}
      />
      <div className="hidden lg:grid">
        {storageUnits.map((storageUnit, index) => (
          <div
            key={index}
            className={`grid grid-cols-12 gap-4 mt-4 pl-7 pr-2 py-2 ${index % 2 === 1 ? "rounded-4xl bg-light-gs-green" : ""
              }`}
          >
            <div className="col-span-3 flex items-center">
              <span className="font-medium pr-1">
                {storageUnit.siteData.name}
              </span>
              {`- ${storageUnit.unitData.size} - ${formatAreaSize(
                storageUnit.unitData.areaSize
              )}`}
              m<span className="align-super text-[10.5px]">2</span>
            </div>
            <div className="col-span-1 flex items-center">
              {storageUnit.unitData.unitNumber}
            </div>
            <div className="col-span-1 flex items-center">{`${formatCurrency(
              storageUnit.unitData.price,
              requestContext.translations
            )}`}</div>
            <div className="col-span-2 flex items-center">
              {storageUnit.unitData.unitNoticeLeaveDate === null
                ? storageUnit.unitData.startDate === null
                  ? requestContext.translations.pages.myPagesPage.booked
                  : `${requestContext.translations.pages.myPagesPage.bookedFrom} ${storageUnit.unitData.startDate}`
                : `${requestContext.translations.pages.myPagesPage.unitCancelledFrom} ${storageUnit.unitData.unitNoticeLeaveDate}`}
            </div>
            <div className="col-span-5 flex items-center justify-end">
              {storageUnit.unitData.unitNoticeLeaveDate !== null ||
                (canChangeUnit && (
                  <button
                    onClick={() => {
                      showMainLoader();
                      window.location.href = `${requestContext.configurations.pages.changeStorageUnitPage.url}?unitNumber=${storageUnit.unitData.unitNumber}&siteId=${storageUnit.siteData.id}&size=${storageUnit.unitData.size}&customerId=${customerId}`;
                    }}
                    className="btn mr-5"
                  >
                    {
                      requestContext.translations.pages.myPagesPage
                        .changeStorageUnit
                    }
                  </button>
                ))}
              {storageUnit.unitData.unitNoticeLeaveDate == null && (
                <>
                  <button
                    onClick={() => handleOpenInsuranceModal(storageUnit)}
                    className="btn mr-5"
                  >
                    {requestContext.translations.general.insurance}
                  </button>
                  <button
                    onClick={() => handleOpenTerminateModal(storageUnit)}
                    className="btn dark-contrast"
                  >
                    {requestContext.translations.pages.myPagesPage.terminate}
                  </button>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="grid gap-4 lg:hidden">
        {storageUnits.map((storageUnit, index) => (
          <Accordion
            key={index}
            title={`${storageUnit.siteData.name} - ${storageUnit.unitData.size} - ${formatAreaSize(storageUnit.unitData.areaSize)}`}
            titleElement={
              <div
                className={`col-span-4 flex items-center ${styles.mobileHeader}`}
                data-header={requestContext.translations.pages.myPagesPage.unit}
              >
                <span className="font-medium pr-1">
                  {storageUnit.siteData.name}
                </span>
                {` - ${storageUnit.unitData.size} - ${formatAreaSize(
                  storageUnit.unitData.areaSize
                )}`}
                m<span className="align-super text-[10.5px]">2</span>
              </div>
            }
            translations={requestContext.translations}
            className={`${styles.mobileInvoiceAccordion} rounded-[16px]`}
          >
            <div key={index} className={`grid grid-cols-2 gap-4`}>
              <div
                className={`col-span-1 flex items-center ${styles.mobileHeader}`}
                data-header={
                  requestContext.translations.pages.myPagesPage.number
                }
              >
                {storageUnit.unitData.unitNumber}
              </div>
              <div
                className={`col-span-1 flex items-center ${styles.mobileHeader}`}
                data-header={requestContext.translations.general.price}
              >{`${formatCurrency(
                storageUnit.unitData.price,
                requestContext.translations
              )}`}</div>

              <div
                className={`col-span-1 flex items-center ${styles.mobileHeader}`}
                data-header={
                  requestContext.translations.pages.myPagesPage.status
                }
              >
                {storageUnit.unitData.unitNoticeLeaveDate === null
                  ? storageUnit.unitData.startDate === null
                    ? requestContext.translations.pages.myPagesPage.booked
                    : `${requestContext.translations.pages.myPagesPage.bookedFrom} ${storageUnit.unitData.startDate}`
                  : `${requestContext.translations.pages.myPagesPage.unitCancelledFrom} ${storageUnit.unitData.unitNoticeLeaveDate}`}
              </div>

              <div className="col-span-2 flex flex-col gap-4 pt-4 items-center justify-end">
                {storageUnit.unitData.unitNoticeLeaveDate !== null ||
                  (canChangeUnit && (
                    <button
                      onClick={() => {
                        window.location.href = `${requestContext.configurations.pages.changeStorageUnitPage.url}?unitNumber=${storageUnit.unitData.unitNumber}&siteId=${storageUnit.siteData.id}&size=${storageUnit.unitData.size}&customerId=${customerId}`;
                      }}
                      className="btn w-full text-center"
                    >
                      {
                        requestContext.translations.pages.myPagesPage
                          .changeStorageUnit
                      }
                    </button>
                  ))}
                {storageUnit.unitData.unitNoticeLeaveDate == null && (
                  <>
                    <button
                      onClick={() => handleOpenInsuranceModal(storageUnit)}
                      className="btn w-full"
                    >
                      {requestContext.translations.general.insurance}
                    </button>
                    <button
                      onClick={() => handleOpenTerminateModal(storageUnit)}
                      className="btn w-full dark-contrast"
                    >
                      {requestContext.translations.pages.myPagesPage.terminate}
                    </button>
                  </>
                )}
              </div>
            </div>
          </Accordion>
        ))}
      </div>
      <Modal
        isOpen={isInsuranceModalOpen}
        onClose={handleCloseInsuranceModal}
        closeTranslation={requestContext.translations.general.closePopup}
        className="w-500-responsive"
      >
        {selectedStorageUnit && (
          <ChangeInsurance
            onClose={handleCloseInsuranceModal}
            requestContext={requestContext}
            storageUnit={selectedStorageUnit}
            customerId={customerId}
          />
        )}
      </Modal>
      <Modal
        isOpen={isTerminateModalOpen}
        onClose={handleCloseTerminateModal}
        closeTranslation={requestContext.translations.general.closePopup}
        className="w-500-responsive"
      >
        {selectedStorageUnit && (
          <TerminateStorageUnit
            onClose={handleCloseTerminateModal}
            requestContext={requestContext}
            storageUnit={selectedStorageUnit}
            customerId={customerId}
          />
        )}
      </Modal>
    </div>
  );
}
